import { useCallback, useContext } from 'react';
import { removeUserInfo } from '@/utils/helper';
import { userContext } from '@/components/UserProvider/Context';
import { useAppContext } from './useApp';
import { useRouter } from './useRouter';

export const useUser = () => {
  return useContext(userContext);
};

// start_ai_generated
/**
 * Custom hook for re-authenticating the user.
 * Removes user info from the app context and navigates to the homepage with the specified app query parameter.
 * @returns {Object} An object containing the `mutate` function.
 */
// end_ai_generated
export const useUserReAuth = () => {
  const { app } = useAppContext();
  const { navigate } = useRouter();

  const mutate = useCallback(() => {
    removeUserInfo(app);
    navigate(`/?app=${app}`, {
      redirect: window.location.href,
    });
  }, [app, navigate]);

  return {
    mutate,
  };
};

// start_ai_generated
/**
 * Custom hook for user logout functionality.
 * @returns {Object} An object containing the `mutate` function.
 */
// end_ai_generated
export const useUserLogout = () => {
  const { app } = useAppContext();

  const mutate = useCallback(() => {
    removeUserInfo(app);
    window.location.href = `/account/logout?app=${app}`;
  }, [app]);

  return {
    mutate,
  };
};
