import React, { useMemo } from 'react';
import { GlobalUIProvider } from '@/components/GlobalUIProvider';
import { parse } from 'query-string';
import { storeAccessTokens, stores } from '@@/config';
import { AppContext, setAppContextValue } from './context';
import { PageDataProvider } from '../PageDataProvider';
import { ShopProvider } from '../ShopProvider';

export function AppProvider({ data, location, pageContext, children }) {
  const { app: appFromQuery } = parse(location.search);

  const appContextValue = useMemo(() => {
    if (appFromQuery && appFromQuery !== pageContext.app) {
      // 优先app参数判断
      let store = stores.find((item) => item.app === appFromQuery || item?.appAlias?.includes(appFromQuery));

      if (!store) {
        store = stores.find((item) => item.brand === pageContext.brand && item.country === 'us') || stores[0];
      }

      return {
        app: store.app,
        language: store.lang,
        brand: process.env.BRAND,
        store: store.domain,
        locale: store.locale || store.country,
        defaultLocale: 'us',
        locales: pageContext.locales,
        storeAccessToken: storeAccessTokens[store.country],
        pathname: location.pathname,
        pathPrefix: store.country !== 'us' ? `/${store.locale || store.country}/` : '/',
        isApp: store.type === 'app',
        location,
      };
    }

    return pageContext;
  }, [pageContext, location, appFromQuery]);

  setAppContextValue(appContextValue);

  return (
    <AppContext.Provider value={appContextValue}>
      <ShopProvider app={appContextValue.app} storeAccessToken={appContextValue.storeAccessToken}>
        <PageDataProvider
          location={location}
          pageContext={appContextValue}
          language={appContextValue.language}
          data={data}
        >
          <GlobalUIProvider>{children}</GlobalUIProvider>
        </PageDataProvider>
      </ShopProvider>
    </AppContext.Provider>
  );
}
