import { useAppContext } from '@/hooks/useApp';
import { usePageData } from '@/hooks/usePageData';
import { useUser } from '@/hooks/useUser';
import { SHA256 } from 'crypto-js';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';

const BRAND_ICON = {
  anker: '//cdn.shopify.com/s/files/1/0493/9834/9974/files/2021325-174322_180x180.png',
  eufy: '//cdn.shopify.com/s/files/1/0504/7094/4954/files/favicon.png?v=1711955983',
  nebula: '//cdn.shopify.com/s/files/1/0511/7666/9359/files/web_logo_180x180.jpg?v=1622627592',
  soundcore: 'https://cdn.shopify.com/s/files/1/0517/2199/4432/files/blue-d_128.png?v=1661137055',
  ankerwork:
    '//cdn.shopify.com/s/files/1/0580/2262/5458/files/AW_Wordmark_64x64_0fefd9da-823d-448e-b20f-f0a857d6833f_180x180.png',
  mach: '//mach.tech/icon.png',
  ankermake: '//www.ankermake.com/favicon.ico',
};

const GTM_ID_MAP = {
  anker: 'GTM-KFSVH5H',
  soundcore: 'GTM-KV3RFK2',
  eufy: 'GTM-MNZC3RB',
  nebula: 'GTM-N3BNRDT',
  ankerwork: 'GTM-5BMS5TD',
  ankermake: 'GTM-KXMCK6M',
};

export function HeadContainer() {
  const { language, brand, locale } = useAppContext();

  const { data } = usePageData();

  const noGTMCountries = useMemo(() => locale === 'jp', [locale]);

  const gtmId = useMemo(() => {
    return GTM_ID_MAP[brand] || '';
  }, [brand]);

  const { userInfo } = useUser();

  const collect = useCallback(async () => {
    if (userInfo?.email) {
      const hashedEmail = SHA256(userInfo.email).toString();

      window.shop_country_code = locale;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        country_code: locale, // 需规范，有部分站点传成了us.anker.com的格式，需改为两位小写字母的国家缩写；另外需注意，英国统一使用uk，不用gb
        user_id: userInfo.user_id, // 保持以前埋点即可,此为注册或登录时上传的加密注册ID
        user_hashed_email: hashedEmail, // 此为注册或登录时上传的加密注册邮箱，需修改为sha256加密算法
        user_agent: navigator.userAgent, // 保持以前埋点即可,此为用户的user agent
        login_status: 'logged', // 保持以前埋点即可，此为用户的登录状态
        page_type: 'headless', // 无头页标记，如已有，保持以前埋点即可
      });
    }
  }, [userInfo, locale]);

  useEffect(() => {
    collect();
  }, [collect]);

  return (
    <Helmet
      htmlAttributes={{
        lang: language || 'en',
        class: brand,
      }}
    >
      <meta name="robots" content="noindex, nofollow" />
      {/* TODO: page title */}
      <title>{data.title}</title>
      <link rel="stylesheet" href="//at.alicdn.com/t/c/font_2160649_8i0lcp4qvf2.css" />
      {!!brand && <link rel="shortcut icon" type="image/x-icon" href={BRAND_ICON[brand]} />}
      <script
        type="text/javascript"
        src={`https://www.recaptcha.net/recaptcha/api.js?render=${process.env.GOOGLE_RECAPTCHA_KEY}`}
      />
      {!!gtmId && !noGTMCountries && (
        <script type="text/javascript">
          {`window.shop_country_code="${locale}";(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      j.addEventListener('load', function() {let gtmLoadedEvent = new CustomEvent('gtmLoaded', { bubbles: true });setTimeout(function() {window.dispatchEvent(gtmLoadedEvent);}, 500)})
      })(window,document,'script','dataLayer','${gtmId}');`}
        </script>
      )}
    </Helmet>
  );
}
