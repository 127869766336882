import React from 'react';
import { HeadContainer } from '@/components/HeadContainer';
import { ToastProvider } from '../Toast';

export function GlobalUIProvider({ children, props }) {
  return (
    <ToastProvider>
      <HeadContainer />
      {children}
    </ToastProvider>
  );
}
