import { CrashError, ErrorTypes } from '@/utils/error';
import * as Sentry from '@sentry/gatsby';

Sentry.init({
  dsn: 'https://5a9c0a32d890d344f661e39482fc4d18@o487797.ingest.us.sentry.io/4506012408676352',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend: (event, hint) => {
    const exception = hint.originalException;
    console.log('exception instanceof CrashError:', exception, exception instanceof CrashError);
    if (exception instanceof CrashError) {
      // eslint-disable-next-line no-param-reassign
      event.fingerprint = [ErrorTypes.CrashError];
      return event;
    } else {
      return null;
    }
  },
});
