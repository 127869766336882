export enum ErrorTypes {
  CrashError = 'CrashError',
  ApiError = 'ApiError',
}

// 页面崩溃错误
export class CrashError extends Error {
  constructor(name: string, ...args) {
    super(name, ...args);
    this.name = ErrorTypes.CrashError;
  }
}

// API 请求错误
export class ApiError extends Error {
  constructor(name: string, ...args) {
    super(name, ...args);
    this.name = ErrorTypes.ApiError;
  }
}
