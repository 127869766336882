export const DEFAULT_PRODUCT_IMAGE = 'https://cdn.shopify.com/s/files/1/0504/7094/4954/files/2021315-175221.jpeg';

export const WAREHOUSE_ADDRESS = {
  ca: ['Winston Wang', '1665 Lincoln Ave.', 'Montreal, QC H3H2T7', 'Canada'],
  eu: ['Anker c/o OUBO', 'Wächtersbacher Str. 76 ', 'UG im Hof', '60386', 'Frankfurt', 'Germany'],
  us: ['AnkerDirect', '5350 Ontario Mills Pkwy Ste 100', 'Ontario, CA 91764-5137'],
  uk: ['Anker Innovations', '075-4241-8559', 'Unit 7, seawall court, Seawall Road', 'Cardiff, CF24 5PQ'],
};

export const COURIER = {
  us: ['Fedex', 'UPS', 'USPS'],
  ca: ['BNI', 'Canada Post', 'Fedex', 'Intelcom', 'UPS', 'Purolator'],
  uk: ['DHL', 'DPD', 'Hermes UK', 'UPS', 'Parcelforce', 'Royal Mail', 'Yodel'],
  eu: ['DHL', 'UPS'],
};

export const ORDER_STATUS_OLD = {
  cancelled: 1,
  partiallyRefunded: 2,
  refunded: 3,
  fulfilled: 4,
  partial: 5,
  processing: 6,
};

export const ORDER_STATUS = {
  cancelled: { color: '#999', id: 'cancelled' },
  partiallyRefunded: { color: '#F84D4F', id: 'partiallyRefunded' },
  refunded: { color: '#F84D4F', id: 'refunded' },
  shipped: { color: '#52C41A', id: 'shipped' },
  partially: { color: '#52C41A', id: 'partially' },
  processing: { color: '#FAAD15', id: 'processing' },
};

export const brandColor = {
  anker: '#00a7e1',
  eufy: '#005D8E',
  soundcore: '#17bbef',
  ankerwork: '#007dff',
  ankermake: '#88f387',
  nebula: '#d71c35',
  mach: '#000',
};

export const CountryMap = {
  us: 'us',
  uk: 'uk',
  'eu-en': 'eu',
  'eu-de': 'de',
  'eu-fr': 'fr',
  'eu-it': 'it',
  fr: 'fr',
  ca: 'ca',
  'app-eu': 'eu',
};

export const TimeFormatMap = {
  us: 'MM/DD/YYYY hh:mm:ss A',
  uk: 'DD/MM/YYYY hh:mm:ss',
  'eu-en': 'DD.MM.YYYY hh:mm:ss',
  'eu-de': 'DD.MM.YYYY hh:mm:ss',
  'eu-fr': 'DD -MM -YYYY hh:mm:ss',
  'eu-it': 'DD.MM.YYYY hh:mm:ss',
  fr: 'DD -MM -YYYY hh:mm:ss',
  ca: 'YYYY/MM/DD hh:mm:ss',
  'app-eu': 'DD.MM.YYYY hh:mm:ss',
};

export const CountryCodeMap = {
  us: 'US',
  uk: 'GB',
  'eu-en': '',
  'eu-de': 'DE',
  'eu-fr': 'FR',
  'eu-it': 'IT',
  fr: 'fr',
  ca: 'CA',
  'eu-es': 'ES',
  'app-eu': '',
};

export const languageTerritoryMapping = {
  us: 'en-US',
  uk: 'en-GB',
  ca: 'en-CA',
  fr: 'fr-FR',
  au: 'en-AU',
  'eu-de': 'de-DE',
  'eu-en': 'en-DE',
};

export const currencyCodeMap = {
  us: 'USD',
  uk: 'GBP',
  ca: 'CAD',
  fr: 'EUR-FR',
  au: 'AUD',
  'eu-de': 'EUR',
  'eu-en': 'EUR',
};
